<template>
  <div class="home-container"></div>
</template>

<script>
export default {
  name: 'Home',
  props: {},
  metaInfo: {
    title: 'vlasiuc',
    meta: [
      {
        name: 'description',
        content: 'Gooo faster... focusing on web ecommerce development',
      },
      {
        property: 'og:title',
        content: 'vlasiuc',
      },
      {
        property: 'og:description',
        content: 'Gooo faster... focusing on web ecommerce development',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/f6f78aa1-34a7-4d19-bf81-b69e41b23718/8e9b608e-1d87-4e90-a8be-b1a865c490d5?org_if_sml=1&force_format=original',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #000000;
}
</style>
