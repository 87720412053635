<template>
  <div class="page-container">
    <span>
      <DateTimePrimitive
        format="ddd, MMM D, YYYY h:mm A"
        date="Thu Dec 19 2024 15:39:44 GMT+0200 (Eastern European Standard Time)"
      ></DateTimePrimitive>
    </span>
    <lottie-vue-player
      :src="`https://presentation-website-assets.teleporthq.io/features/lottie.json`"
      :autoplay="true"
      :speed="1"
      :backgroundColor="transparent"
      class="page-lottie-node"
    ></lottie-vue-player>
  </div>
</template>

<script>
import { DateTimePrimitive } from '@teleporthq/react-components'

export default {
  name: 'Page',
  props: {},
  components: {
    DateTimePrimitive,
  },
  metaInfo: {
    title: 'Page - vlasiuc',
    meta: [
      {
        name: 'description',
        content: 'Gooo faster... focusing on web ecommerce development',
      },
      {
        property: 'og:title',
        content: 'Page - vlasiuc',
      },
      {
        property: 'og:description',
        content: 'Gooo faster... focusing on web ecommerce development',
      },
      {
        property: 'og:image',
        content:
          'https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/f6f78aa1-34a7-4d19-bf81-b69e41b23718/8e9b608e-1d87-4e90-a8be-b1a865c490d5?org_if_sml=1&force_format=original',
      },
    ],
  },
}
</script>

<style scoped>
.page-container {
  width: 100%;
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
 
.page-lottie-node {
  width: 300px;
  height: 300px;
}
</style>
